import React from 'react'

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_mail">
          <p>coming soon</p>
        </div>
      </div>
    </footer>
  )
}
